const russian = {
  illumination: {
    dark: 'Вас не видно, включите свет'
  },
  forward: 'Поместите голову в центр овала и смотрите прямо',
  left: 'Поверните голову влево',
  right: 'Поверните голову вправо',
  success: 'Успешно пройдено',
  failure: 'Не пройдено',
  center: 'Поместите голову в центр овала, и смотрите прямо',
  further: 'Встаньте дальше и поместите голову в центр овала',
  closer: 'Встаньте ближе и поместите голову в центр овала',
  result: {
    success: 'Верификация пройдена успешно!',
    failure: 'Верификация не пройдена!',
    restart: 'Пройти заново',
    criminalButton: 'Проверить на нежелательные лица',
    criminalResponse: 'Данных не найдено',
    continue: 'Далее',
    fields: {
      barcode: 'Штрихкод',
      face_photo: 'Фотография Liveness',
      images: 'Фотография лица',
      created_at: 'Дата прохождения',
      failure_reason: 'Причина провала',
      undisclosed: 'Не выявлена',
      darkness: 'Слишком темно',
      brightness: 'Слишком светло',
      prediction: 'Процент живости',
      liveness_result: 'Результат лайвнесс',
      liveness_photo: 'Фотография лайвнесс',
      document_photo: 'Фотография с документа',
      prediction_percent: 'Процент схожести',
      first_name: 'Имя',
      last_name: 'Фамилия',
      patronymic: 'Отчество',
      middle_name: 'Отчество',
      personal_number: 'ИИН',
      iin: 'ИИН',
      date_of_birth: 'Дата рождения',
      birth_date: 'Дата рождения',
      birth_place: 'Место рождения',
      document_number: 'Номер документа',
      doc_number: 'Номер документа',
      authority: 'Орган выдачи',
      issuer: 'Орган выдачи',
      date_of_issue: 'Дата выдачи',
      issued_date: 'Дата выдачи',
      date_of_expiry: 'Срок годности',
      expiration_date: 'Срок годности',
      country_code: 'Код страны',
      no: 'Нет',
      nationality: 'Национальность'
    }
  },
  docFront: 'Поднесите удостоверение личности к камере',
  docBack: 'Пожалуйста, переверните документ',
  docSuccess: 'Успешно',
  docFailure: 'Не пройдено',
  f2fText: 'Процесс Face2Face',
  firstImage: 'Первое изображение',
  secondImage: 'Второе изображение',
  sendButton: 'Сравнить',
  inputText: 'Добавить изображение',
  short: {
    forward: 'Поместите голову в центр овала, смотрите прямо и нажмите на кнопку "сделать фото"',
    failure: 'Пожалуйста попробуйте ещё раз',
    success: 'Успешно'
  },
  cameraSelect: {
    preview: 'Превью с камеры',
    text: 'Пожалуйста, выберите камеру',
    button: 'Выбрать',
    alert: {
      text: 'Вы уверены, что хотите использовать выбранную камеру',
      yes: 'Да',
      no: 'Нет'
    }
  },
  pawn: {
    preSelect: {
      deposit: 'Залог',
      buyout: 'Выкуп'
    },
    auth: {
      header: 'Авторизация',
      phoneLabel: 'Номера телефона',
      IINLabel: 'ИИН',
      IINPlaceholder: 'Введите ИИН',
      checkbox: 'Я даю согласие на использование своего электронного документа',
      approveText: 'Нажимая "ОК" вы даете согласие на использование своего электронного документа',
      lengthError: 'Поле не может быть пустым',
      error: 'Ошибка, проверьте правильность введенных данных',
      smsLabel: 'СМС код',
      smsPlaceholder: 'Введите код',
      select: {
        id: 'Удостоверение личности',
        driver_license: 'Водительское удостоверение',
        birth_cert: 'Свидетельство о рождении',
        marriage_start: 'Свидетельство о заключении брака',
        name_change: 'Свидетельство о смене ФИО',
        marriage_end: 'Свидетельство о расторжении брака',
        diploma: 'Диплом',
        tech_pass: 'Тех. паспорт',
        vacc_pass: 'Паспорт вакцинации'
      }
    },
    contract: {
      contractLabel: 'Номер контракта',
      approveButton: 'Подтвердить контакт',
      endSession: 'Завершить сессию',
      contractClose: 'Контракт успешно закрыт',
      contractAdd: 'Контракт успешно добавлен'
    }
  },
  input: {
    symbolAmount: 'Поле должно содержать %s1 символов'
  },
  fileOrCamera: {
    countrySelect: 'Выберите страну',
    kz: 'Республика Казахстан',
    other: 'Другое',
    docType: 'Выберите тип документа',
    pass: 'Паспорт',
    id: 'Удостоверение личности',
    header: 'Отсканируйте ID-документ в вашем браузере',
    text: 'Используйте камеру вашего устройства для сканирования документа',
    button: 'Использовать камеру',
    alert: {
      alertMessage: 'Вы уверены, что хотите использовать сканирование с камеры?',
      yes: 'Да',
      no: 'Нет'
    }
  },
  distance: {
    tooFar: 'Слишком далеко, встаньте ближе',
    tooClose: 'Слишком близко, встаньте дальше',
    notFound: 'Лицо не обнаружено',
    alert: {
      text: 'Вы уверены, что хотите закрыть окно?',
      yes: 'Да',
      no: 'Нет'
    }
  },
  loader: {
    wait: 'Пожалуйста, не закрывайте вкладку, идет обработка данных...',
    success: 'Верификация прошла успешно!',
    failure: 'Слечение лиц не пройдено!'
  },
  digital: {
    phone: {
      label: 'Номер телефона',
      errors: {
        empty: 'Поле не может быть пустым',
        count: 'Поле должно содержать в себе 11 символов'
      }
    },
    iin: {
      placeholder: 'Введите ИИН',
      label: 'ИИН',
      errors: {
        empty: 'Поле не может быть пустым',
        count: 'Поле должно содержать в себе 12 символов'
      }
    }
  }
}

const english = {
  illumination: {
    dark: 'Can\'t see you, turn on the light'
  },
  forward: 'Place your head in the center of the oval and look straight',
  left: 'Turn your head to the left',
  right: 'Turn your head to the right',
  success: 'Success',
  failure: 'Failure',
  center: 'Place your head in the center of the oval and look forward',
  further: 'Stand further and place your head in the center of the oval',
  closer: 'Stand closer and place your head in the center of the oval',
  result: {
    success: 'Verification passed successfully!',
    failure: 'Verification failed!',
    restart: 'Pass again',
    criminalButton: 'Check for unwanted faces',
    criminalResponse: 'No data found',
    continue: 'Continue',
    fields: {
      barcode: 'Barcode',
      face_photo: 'Liveness photo',
      images: 'Face photo',
      created_at: 'Date passed',
      failure_reason: 'Reason of failure',
      undisclosed: 'Not detected',
      darkness: 'Too dark',
      brightness: 'Too light',
      prediction: 'Liveliness percentage',
      liveness_result: 'Liveness result',
      liveness_photo: 'Liveness photo',
      document_photo: 'Photo from a document',
      prediction_percent: 'Percentage of similarity',
      first_name: 'Name',
      last_name: 'Last name',
      patronymic: 'Patronymic',
      middle_name: 'Patronymic',
      personal_number: 'IIN',
      iin: 'IIN',
      date_of_birth: 'Date of birth',
      birth_date: 'Date of birth',
      birth_place: 'Place of birth',
      document_number: 'Document number',
      doc_number: 'Document number',
      authority: 'Issuing authority',
      issuer: 'Issuing authority',
      date_of_issue: 'Date of issue',
      issued_date: 'Date of issue',
      date_of_expiry: 'Expiration date',
      expiration_date: 'Expiration date',
      country_code: 'Country code',
      no: 'No',
      nationality: 'Nationality'
    }
  },
  docFront: 'Put your ID in front of the camera',
  docBack: 'Please turn over the document',
  docSuccess: 'Success',
  f2fText: 'Face2Face process',
  firstImage: 'First Image',
  secondImage: 'Second Image',
  sendButton: 'Compare',
  inputText: 'Add image',
  docFailure: 'Failed',
  short: {
    forward: 'Place your head in the center of the oval, look straight and press the "take photo" button',
    failure: 'Please try again',
    success: 'Success'
  },
  cameraSelect: {
    preview: 'Camera preview',
    text: 'Please, select the camera',
    button: 'Select',
    alert: {
      text: 'Are you sure you want to use this camera?',
      yes: 'Yes',
      no: 'No'
    }
  },
  pawn: {
    preSelect: {
      deposit: 'Deposit',
      buyout: 'Buyout'
    },
    auth: {
      header: 'Authorization',
      phoneLabel: 'Phone Number',
      IINLabel: 'IIN',
      IINPlaceholder: 'Enter your IIN',
      checkbox: 'I consent to the use of my electronic document',
      approveText: 'By clicking "OK", you consent to the use of your electronic document',
      error: 'Error, please check the correctness of the entered data',
      smsLabel: 'SMS code',
      smsPlaceholder: 'Enter the code',
      select: {
        id: 'ID',
        driver_license: 'Driver license',
        birth_cert: 'Birth certificate',
        marriage_start: 'Marriage certificate',
        name_change: 'Certificate of change of full name',
        marriage_end: 'Certificate of dissolution of marriage',
        diploma: 'Diploma',
        tech_pass: 'Tech. passport',
        vacc_pass: 'Vaccination passport'
      }
    },
    result: {
      success: 'Verification passed successfully',
      failure: 'Verification failed'
    },
    contract: {
      contractLabel: 'Contract number',
      approveButton: 'Approve contract',
      endSession: 'End session',
      contractClose: 'Contract successfully closed',
      contractAdd: 'Contract successfully closed'
    }
  },
  input: {
    symbolAmount: 'Field must contain %s1 symbols'
  },
  fileOrCamera: {
    countrySelect: 'Select the country',
    kz: 'Kazakhstan Republic',
    other: 'Other',
    docType: 'Select the document type',
    pass: 'Passport',
    id: 'ID',
    header: 'Scan your ID-document from your browser',
    text: 'Use your device camera to scan the document',
    button: 'Use camera',
    alert: {
      alertMessage: 'Are you sure you want to use your camera to scan the document?',
      yes: 'Yes',
      no: 'No'
    }
  },
  distance: {
    tooFar: 'Too far, stand closer',
    tooClose: 'Too close, stand further',
    notFound: 'Face not found',
    alert: {
      text: 'Are you sure you want to close this window?',
      yes: 'Yes',
      no: 'No'
    }
  },
  loader: {
    wait: 'Please do not close the tab, data is being processed...',
    success: 'Verification successful!',
    failure: 'Verification failed!'
  },
  digital: {
    phone: {
      label: 'Phone number',
      errors: {
        empty: 'Field cannot be empty',
        count: 'Field must contain at least 11 characters'
      }
    },
    iin: {
      placeholder: 'Enter IIN',
      label: 'IIN',
      errors: {
        empty: 'Field cannot be empty',
        count: 'Field must contain at least 12 characters'
      }
    }
  }
}

const kazakh = {
  illumination: {
    dark: 'Сіз көрінбейсіз, шамды қосыңыз'
  },
  forward: 'Басыңызды сопақшаның ортасына қойып, тіке қараңыз',
  left: 'Басыңызды солға бұрыңыз',
  right: 'Басыңызды оңға бұрыңыз',
  success: 'Сәтті өтті',
  failure: 'Өткен жоқ',
  center: 'Басыңызды сопақшаның ортасына қойып, тіке қараңыз',
  further: 'Әрі қарай тұрып, басыңызды сопақшаның ортасына қойыңыз',
  closer: 'Жақынырақ тұрып, басыңызды сопақшаның ортасына қойыңыз',
  result: {
    success: 'Тексеру сәтті өтті!',
    failure: 'Тексеруден өткен жоқ!',
    restart: 'Қайта өту',
    criminalButton: 'Қажетсіз беттерді тексеріңіз',
    criminalResponse: 'Деректер табылмады',
    continue: 'Келесі',
    fields: {
      barcode: 'Штрихкод',
      face_photo: 'Бетіңіздің суреті',
      images: 'Бетіңіздің суреті',
      created_at: 'Өту күні',
      failure_reason: 'Сәтті өтпеуіңіздің себебі',
      undisclosed: 'Анықталған жоқ',
      darkness: 'Тым қараңғы',
      brightness: 'Тым жарық',
      prediction: 'Жандылық пайызы',
      liveness_result: 'Жандылық нәтижесі',
      liveness_photo: 'Жандылық фотографиясы',
      document_photo: 'Құжат суреті',
      prediction_percent: 'Ұқсастық пайызы',
      first_name: 'Аты',
      last_name: 'Тегі',
      patronymic: 'Әкесінің аты',
      middle_name: 'Әкесінің аты',
      personal_number: 'ЖСН',
      iin: 'ЖСН',
      date_of_birth: 'Туылған күні',
      birth_date: 'Туылған күні',
      birth_place: 'Туылған жері',
      document_number: 'Құжат нөмері',
      doc_number: 'Құжат нөмері',
      authority: 'Беру органы',
      issuer: 'Беру органы',
      date_of_issue: 'Берілген күні',
      issued_date: 'Берілген күні',
      date_of_expiry: 'Жарамдылық мерзімі',
      expiration_date: 'Жарамдылық мерзімі',
      country_code: 'Мемлекет коды',
      no: 'Жоқ',
      nationality: 'Ұлты'
    }
  },
  docFront: 'Жеке куәлікті камераға көрсетіңіз',
  docBack: 'Құжатты аударыңыз',
  docSuccess: 'Сәтті',
  docFailure: 'Өтпеді',
  f2fText: 'Face2Face Процесі',
  firstImage: 'Бірінші сурет',
  secondImage: 'Екінші сурет',
  sendButton: 'Салыстыру',
  inputText: 'Сурет қосу',
  short: {
    forward: 'Басын сопақшаның ортасына қойып, тіке қарап, "суретке түсіру" түймесін басыңыз"',
    failure: 'Қайталап көріңіз',
    success: 'Сәтті өтті'
  },
  cameraSelect: {
    preview: 'Превью с камеры',
    text: 'Камераны таңдаңыз',
    button: 'Таңдау',
    alert: {
      text: 'Сіз таңдаған камераны пайдаланғыңыз келетініне сенімдісіз бе?',
      yes: 'Иә',
      no: 'Жоқ'
    }
  },
  pawn: {
    preSelect: {
      deposit: 'Кепілдік',
      buyout: 'Сатып алу'
    },
    auth: {
      header: 'Авторизация',
      phoneLabel: 'Телефон нөмірлері',
      IINLabel: 'ЖСН',
      IINPlaceholder: 'ЖСН енгізіңіз',
      checkbox: 'Мен электрондық құжатты пайдалануға келісім беремін',
      approveText: '"ОК" батырмасын басу арқылы сіз электрондық құжатты пайдалануға келісім бересіз',
      lengthError: 'Өріс бос болуы мүмкін емес',
      error: 'Қате, енгізілген деректердің дұрыстығын тексеріңіз',
      smsLabel: 'СМС коды',
      smsPlaceholder: 'Кодты енгізіңіз',
      select: {
        id: 'Жеке куәлік',
        driver_license: 'Жүргізуші куәлігі',
        birth_cert: 'Туу туралы куәлік',
        marriage_start: 'Неке қию туралы куәлік',
        name_change: 'Аты-жөнін өзгерту туралы куәлік',
        marriage_end: 'Некені бұзу туралы куәлік',
        diploma: 'Диплом',
        tech_pass: 'Техникалық құжат',
        vacc_pass: 'Вакцинация құжаты'
      }
    },
    contract: {
      contractLabel: 'Келісімшарт нөмірі',
      approveButton: 'Нөмерді растау',
      endSession: 'Сессияны аяқтау',
      contractClose: 'Келісімшарт сәтті жабылды',
      contractAdd: 'Келісімшарт сәтті қосылды'
    }
  },
  input: {
    symbolAmount: 'Өрісте %s1 таңбалары болуы керек'
  },
  fileOrCamera: {
    countrySelect: 'Мемлекетті таңдаңыз',
    kz: 'Қазақстан Республикасы',
    other: 'Басқа',
    docType: 'Құжат түрін таңдаңыз',
    pass: 'Құжат',
    id: 'Жеке куәлік',
    header: 'Браузерде ID құжатын сканерлеңіз',
    text: 'Құжатты сканерлеу үшін құрылғының камерасын пайдаланыңыз',
    button: 'Камераны пайдалану',
    alert: {
      alertMessage: 'Сіз камерадан сканерлеуді қолданғыңыз келетініне сенімдісіз бе?',
      yes: 'Иә',
      no: 'Жоқ'
    }
  },
  distance: {
    tooClose: 'Тым жақын, әрі қарай тұрыңыз',
    tooFar: 'Тым алыс, жақынырақ тұрыңыз',
    notFound: 'Бет табылмады',
    alert: {
      text: 'Сіз осы парақшаның жабылуын растайсыз ба?',
      yes: 'Иә',
      no: 'Жоқ'
    }
  },
  loader: {
    wait: 'Күте тұрыңыз...',
    success: 'Тексеру сәтті аяқталды!',
    failure: 'Бетті сәйкестендіру сәтсіз аяқталды!'
  },
  digital: {
    phone: {
      label: 'Телефон нөмері',
      errors: {
        empty: 'Өріс бос болуы мүмкін емес',
        count: 'Өрісте 11 таңба болуы керек'
      }
    },
    iin: {
      placeholder: 'ЖСН енгізіңіз',
      label: 'ЖСН',
      errors: {
        empty: 'Өріс бос болуы мүмкін емес',
        count: 'Өріс 12 таңбадан тұруы керек'
      }
    }
  }
}

export const languages = {
  ru: russian,
  en: english,
  kz: kazakh
}
