// import { LivenessLogic } from '../logic/LivenessLogic'
import { DocumentLogic } from '../logic/DocumentLogic'
import { F2FLogic } from '../logic/F2FLogic'
import { ResultStructure } from '../struct/ResultStructure'
// import { FileOrCameraLogic } from '../logic/FileOrCameraLogic'
// import { CameraLogic } from '../logic/CameraLogic'
import { DistanceLogic } from '../logic/DistanceLogic'

// import '../styles/LivenessDesktop.css'
// import '../styles/LivenessMobile.css'
import '../styles/DistanceDesktop.css'
import '../styles/DistanceMobile.css'
import '../styles/documentDesktop.css'
import '../styles/documentMobile.css'
import '../styles/F2FDesktop.css'
import '../styles/F2FMobile.css'
// import '../styles/FileOrCameraDesktop.css'
// import '../styles/FileOrCameraMobile.css'
import '../styles/CameraDesktop.css'
import '../styles/CameraMobile.css'

import '../styles/resultDesktop.css'
import '../styles/resultMobile.css'

import axios from 'axios'

const backURL = process.env.APP_BACKEND_URL

async function init (props) {
  if (!props.mainSession) props.mainSession = await getMainSessionID(props.API_KEY)
  if (!props.mainConfig) props.mainConfig = await getMainConfig(props.API_KEY)
  let DVResult
  let FVResult
  props.calledFrom = 'BiometricPackage'
  // const LVResult = await initLiveness(props)
  const LDResult = await initDistance(props)
  if (LDResult.status) {
    DVResult = await initDocument(props)
  } else {
    const RObject = new ResultStructure(LDResult, undefined, undefined, props.mainSession)
    return {
      status: false,
      lv: LDResult
    }
  }

  if (DVResult.status) {
    FVResult = await initF2F(props)
  } else {
    const RObject = new ResultStructure(undefined, DVResult, undefined, props.mainSession)
    return {
      status: false,
      lv: LDResult,
      dv: DVResult
    }
  }

  if (FVResult.status && DVResult.status && LDResult.status && props.mainConfig.show_full_result) {
    const RObject = new ResultStructure(LDResult, DVResult, FVResult, props.mainSession)
  } else if (!FVResult.status) {
    const RObject = new ResultStructure(undefined, undefined, FVResult, props.mainSession)
  }

  return {
    status: LDResult.status && DVResult.status && FVResult.status,
    lv: LDResult,
    dv: DVResult,
    fv: FVResult,
    main_session: props.mainSession
  }
}

async function getMainSessionID (API_KEY) {
  try {
    const response = await axios.post(`${backURL}/main/session/`, null, {
      headers: {
        Authorization: `API_KEY ${API_KEY}`
      }
    })
    return response.data.session
  } catch (e) {
    throw new Error('Cannot create main session')
  }
}

async function getMainConfig (API_KEY) {
  try {
    const response = await axios.get(`${backURL}/organization/configs/widget/extra/`, {
      headers: {
        Authorization: `API_KEY ${API_KEY}`
      }
    })
    return response.data
  } catch (e) {
    throw new Error('Cannot get configs')
  }
}

async function initDistance (props) {
  const LDObject = new DistanceLogic(props)
  LDObject.startLoader('Пожалуйста, предоставьте доступ к камере и подготовьтесь к прохождению видеопроверки')
  await LDObject.startVideoStream()
  LDObject.stopLoader()
  await LDObject.process()
  return LDObject.result
}

async function initDocument (props) {
  const DVObject = new DocumentLogic(props)
  await DVObject.startVideoStream()
  const DVResult = await DVObject.process()
  if (!props.calledFrom) {
    const DVObject = new ResultStructure(DVResult)
  }
  return DVResult
}

// TODO: Create FaceLogic class
async function initF2F (props) {
  const FVObject = new F2FLogic(props)
  await FVObject.process()
  return FVObject.result
}

export default { init }
