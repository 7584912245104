// noinspection DuplicatedCode

import axios from 'axios'
import { F2FStructure } from '../struct/F2FStructure'
import { languages } from '../utils/lang'

const backURL = process.env.APP_BACKEND_URL

async function pause (seconds) {
  return await new Promise((resolve) => setTimeout(resolve, seconds * 1000))
}

export class F2FLogic extends F2FStructure {
  constructor (props) {
    super()
    this.API_KEY = props.API_KEY
    this.mainSession = props.mainSession

    this.isFinished = false
    this.result = {
      status: false,
      data: {
        prediction: null,
        prediction_percent: 0,
        liveness_photo: '',
        document_photo: ''
      },
      main_session: this.mainSession
    }
    this.language = localStorage.getItem('language') || 'ru'
  }

  async process () {
    if (this.isFinished) {
      this.removeStructure()
      return this.result
    }
    await this.resultAPI()
    return this.process()
  }

  async resultAPI () {
    this.changeLoaderText(languages[this.language].loader.wait)
    const formData = new FormData()
    formData.append('main_session_id ', this.mainSession)
    try {
      const response = await axios.post(`${backURL}/face2face/session/`, formData)
      if (response.data.result) {
        this.changeLoaderText(languages[this.language].loader.success)
        this.result.status = response.data.result
        this.result.data.prediction = response.data.prediction
        this.result.data.prediction_percent = response.data.prediction_percent
        this.result.data.liveness_photo = response.data.liveness_photo
        this.result.data.document_photo = response.data.document_photo
        this.isFinished = true
      } else {
        this.changeLoaderText(languages[this.language].loader.failure)
        this.result.status = false
        this.isFinished = true
      }
    } catch (e) {
      console.log('F2F: ', e)
      this.changeLoaderText(languages[this.language].loader.failure)
      this.result.status = false
      this.isFinished = true
      await pause(1)
    }
  }
}
