// noinspection DuplicatedCode

import soundFileCloser from '../assets/sound/distance/distance_closer.mp3'
import soundFileToCenter from '../assets/sound/distance/distance_start.mp3'

import { DistanceStructure } from '../struct/DistanceStructure'
import axios from 'axios'

import { startNurbek, stopNurbek } from '../utils/client'
import { languages } from '../utils/lang'
import { saveAndCheckSessions } from '../utils/antiscam'

const backURL = process.env.APP_BACKEND_URL

async function pause (seconds) {
  return await new Promise((resolve) => setTimeout(resolve, seconds * 1000))
}

export class DistanceLogic extends DistanceStructure {
  constructor (props) {
    super()

    this.mainSession = props.mainSession
    this.mainConfig = props.mainConfig
    this.API_KEY = props.API_KEY
    this.isFinished = false
    this.videoRecord = false
    this.result = {
      status: false,
      abort: false,
      reason: '',
      data: {
        face_photo: '',
        prediction: 0
      },
      main_session: this.mainSession
    }
    this.steps = this.generateSteps()
    this.uploadTimeSeconds = 1
    this.imageQuality = 1
    this.audio = new Audio()
    this.videoFile = null
    this.videoProcess = null

    this.langButton.onclick = () => {
      this.changeLanguage((language) => {
        for (let i = 0; i < this.steps.length; i++) {
          const key = this.steps[i].stepName
          this.steps[i].text = languages[language][key]
        }
        this.alertMessage.innerText = languages[this.language].distance.alert.text
        this.okButton.innerText = languages[this.language].distance.alert.yes
        this.cancelButton.innerText = languages[this.language].distance.alert.no
      })
    }

    this.closeButton.onclick = () => {
      this.alert.setAttribute('class', 'visible')
    }

    this.okButton.onclick = () => {
      this.isFinished = true
      this.result.abort = true
      this.result.reason = 'abort'
      axios.post(`${backURL}/liveness/failure-reason/`, {
        main_session_id: this.mainSession,
        failure_reason: this.result.reason
      }, {
        headers: {
          Authorization: `API_KEY ${this.API_KEY}`
        }
      })
      this.removeStructure()
      this.stopMediaTracks(this.video.srcObject)
    }

    this.cancelButton.onclick = () => {
      this.alert.setAttribute('class', 'hidden')
    }
  }

  generateSteps () {
    const steps = [
      {
        stepName: 'center',
        text: languages[this.language].center,
        finished: false,
        audioPlayed: true,
        audioPositionPlayed: false,
        audio: soundFileToCenter,
        successCounter: 0
      },
      {
        stepName: 'closer',
        text: languages[this.language].closer,
        finished: false,
        audioPlayed: true,
        audioPositionPlayed: false,
        audio: soundFileCloser,
        successCounter: 0
      }
    ]
    return steps
  }

  startFrontendRecording (stream) {
    const options = {
      bitsPerSecond: 128000
    }
    const recorder = new MediaRecorder(stream, options)
    const data = []
    recorder.ondataavailable = (event) => {
      data.push(event.data)
    }
    recorder.start(1000)
    const stopped = new Promise((resolve, reject) => {
      recorder.onstop = resolve
      recorder.onerror = (event) => reject(event.name)
    })
    return Promise.all([stopped, null]).then(() => data)
  }

  async sendVideo (session, file) {
    const fd = new FormData()
    fd.append('main_session_id', session)
    fd.append('video', file)
    try {
      return await axios.post(`${backURL}/liveness/video/receiver/`, fd)
    } catch (e) {
      console.log(e)
      return e
    }
  }

  async startVideoStream (width = 1280, height = 720) {
    let constraints = {
      video: {
        facingMode: 'user',
        width: 1280,
        height: 720
      }
    }
    if (window.innerWidth > 650) {
      constraints = {
        video: {
          facingMode: 'user',
          width: {
            min: 640,
            ideal: width,
            max: 1920
          },
          height: {
            min: 480,
            ideal: height,
            max: 1080
          }
        }
      }
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints)

      this.streamSettings = stream.getVideoTracks()[0].getSettings()
      this.setStructureSize(width, height)

      this.video.srcObject = stream
      this.video.onloadedmetadata = () => {
        this.captureFrames = true
      }
      await this.estimateConnectionSpeed()
    } catch (error) {
      this.result.reason = 'no_camera'
      axios.post(`${backURL}/liveness/failure-reason/`, {
        main_session_id: this.mainSession,
        failure_reason: this.result.reason
      }, {
        headers: {
          Authorization: `API_KEY ${this.API_KEY}`
        }
      })
      throw new Error('cannot set video stream')
    }
  }

  stopVideoStream () {
    if (this.video.srcObject) {
      const tracks = this.video.srcObject.getTracks()
      tracks.forEach(track => track.stop())
    }
  }

  startLoader (text) {
    this.loaderText.innerText = text
    this.livenessWrapper.setAttribute('class', 'hidden')
    this.recordingLoader.setAttribute('class', 'loader')
  }

  stopLoader () {
    this.livenessWrapper.removeAttribute('class')
    this.recordingLoader.removeAttribute('class')
  }

  async process () {
    if (!this.videoRecord && this.mainConfig.video_recording) {
      try {
        this.videoProcess = this.startFrontendRecording(this.video.srcObject)
        startNurbek(this.mainSession, this.video.srcObject)
        this.videoRecord = true
      } catch (e) {
        console.log(e)
      }
    }
    if (this.isCloseButtonCLicked) {
      this.isFinished = this.isCloseButtonCLicked
      this.result.abort = true
      this.result.reason = 'abort'
      axios.post(`${backURL}/liveness/failure-reason/`, {
        main_session_id: this.mainSession,
        failure_reason: this.result.reason
      }, {
        headers: {
          Authorization: `API_KEY ${this.API_KEY}`
        }
      })
      for (let i = 0; this.steps.length > i; i++) {
        this.steps[i].audioPlayed = true
        this.steps[i].audioPositionPlayed = true
      }
      this.audio.src = ''
      this.audio.pause()
    }
    if (this.isFinished) {
      await this.telegramBotApi(
          `${this.mainSession}`,
          'Liveness',
          `Prediction: ${this.result.data.prediction ? this.result.data.prediction : 'N/A'}`,
          this.result.status,
          this.result.status ? this.result.data.face_photo : this.photo
      )
      if (this.mainConfig.id === 25 || this.mainConfig.id === 108 || this.mainConfig.id === 114 || this.mainConfig.id === 142) {
        await this.telegramBotApi(
            `${this.mainSession}`,
            'Liveness',
            `Prediction: ${this.result.data.prediction ? this.result.data.prediction : 'N/A'}`,
            this.result.status,
            this.result.status ? this.result.data.face_photo : this.photo,
            '-1001836634629'
        )
      }

      if (this.mainConfig.video_recording) {
        console.log('trying to stop videostream webrtc')
        try {
          stopNurbek()
        } catch (e) {
          console.log(e)
        }
      }
      this.stopVideoStream()
      try {
        await this.videoProcess.then(async (recordedChunks) => {
          const recordedBlob = new Blob(recordedChunks, { type: 'video/webm' })
          this.videoFile = new File([recordedBlob], 'fvr.webm')
        })
        this.startLoader('Пожалуйста, не закрывайте вкладку, идет обработка данных')
        await this.sendVideo(this.mainSession, this.videoFile)
      } catch (e) {
        console.log('something happened during video stop', e)
      }
      this.removeStructure() // TODO: Test this method
      return this.result
    }
    if (!this.captureFrames) {
      await pause(1)
      return this.process()
    }
    this.photo = this.getUserPhotoAsFile()
    for (let index = 0; index < this.steps.length; index++) {
      if (!this.steps[index].audioPlayed) {
        try {
          if (this.audio) this.audio.pause()
          this.audio.src = this.steps[index].audio
          await this.audio.play()
          this.steps[index].audioPlayed = true
        } catch (error) {
          console.log(error)
        }
      }

      if (!this.steps[index].finished) {
        await this.stepPosition(index)
        break
      }
    }

    if (this.allStepsDone()) {
      await this.resultAPI()
      this.stopVideoStream()
    }
    return this.process()
  }

  getUserPhotoAsFile () {
    const photoBase64 = this.getUserPhotoBase64()
    return this.convertToFile(photoBase64, 'facePhoto.jpg')
  }

  getUserPhotoBase64 () {
    this.canvas.setAttribute('width', `${this.video.videoWidth}`)
    this.canvas.setAttribute('height', `${this.video.videoHeight}`)
    const context = this.canvas.getContext('2d')
    context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height)
    return this.canvas.toDataURL('image/jpeg', this.imageQuality)
  }

  convertToFile (dataURL, filename) {
    let array = []
    let mime = ''
    try {
      array = dataURL.split(',')
      mime = array[0].match(/:(.*?)/)[1] || '.jpeg'
    } catch (e) {
      dataURL = `data:image/png;base64,${dataURL}`
      array = dataURL.split(',')
      mime = array[0].match(/:(.*?)/)[1] || '.jpeg'
    }
    const temp = atob(array[1])
    let n = temp.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = temp.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  async stepPosition (index) {
    const defaultParams = {
      ...this.steps[index],
      index: index,
      border: 'bv-b'
    }
    const failParams = {
      ...this.steps[index],
      index: index,
      border: 'bv-b-cr'
    }

    this.changeUI(defaultParams)
    await pause(1)

    setTimeout(() => {
      if (!this.steps[index].finished && !this.isFinished) {
        this.result.reason = 'timeout'
        axios.post(`${backURL}/liveness/failure-reason/`, {
          main_session_id: this.mainSession,
          failure_reason: this.result.reason
        }, {
          headers: {
            Authorization: `API_KEY ${this.API_KEY}`
          }
        })
        this.changeUI(failParams)
        this.changeSvgColor('#f20000')
        this.isFinished = true
      }
    }, 45 * 1000)

    if (!this.isFinished) {
      await this.headPositionAPI(defaultParams) // Arguments must be transmitted as 'params'
    }
  }

  estimateContentLength (formData) {
    // Seems to be 44 in WebKit browsers (e.g. Chrome, Safari, etc.),
    // but varies at least in Firefox.
    const baseLength = 50 // estimated max value
    // Seems to be 87 in WebKit browsers (e.g. Chrome, Safari, etc.),
    // but varies at least in Firefox.
    const separatorLength = 115 // estimated max value
    let length = baseLength
    const entries = formData.entries()
    for (const [key, value] of entries) {
      length += key.length + separatorLength
      if (typeof value === 'object') {
        length += value.size
      } else {
        length += String(value).length
      }
    }
    return length * 8 / 1000
  }

  connectionSpeedFormData () {
    const photo = this.getUserPhotoAsFile()
    const formData = new FormData()
    formData.append('image', photo)
    formData.append('main_session_id', `${this.mainSession}`)
    return formData
  }

  async estimateConnectionSpeed () {
    await pause(1)
    const formData = this.connectionSpeedFormData()
    const contentLength = this.estimateContentLength(formData)
    let averageSpeed = 0
    let averageTime = 0

    for (let i = 0; i < 5; i++) {
      const duration = await this.connectionSpeedDuration(formData)
      if (duration === -9999) return this.setQuality(60, 400)
      averageSpeed += contentLength / duration
      averageTime += duration
    }
    averageSpeed = (averageSpeed / 5).toFixed(2)
    averageTime = (averageTime / 5).toFixed(2)
    return this.setQuality(averageTime, averageSpeed)
  }

  async connectionSpeedDuration (formData) {
    try {
      const url = backURL.slice(0, backURL.length - 3)
      const startTime = (new Date()).getTime()
      await axios.post(`${url}/health`, formData, {
        timeout: 3000
      })
      const endTime = (new Date()).getTime()
      return (endTime - startTime) / 1000 - 0.1
    } catch (error) {
      if (error.code === 'ECONNABORTED') return -9999
    }
  }

  // Check passive liveness model response by image quality
  async checkQualityAPI () {
    for (let i = 1; i > 0.15; i -= 0.05) {
      const prediction = this.testQualityAPI(i)
      console.log(`Quality: ${i}\tPrediction: ${prediction}`)
    }
  }

  async testQualityAPI (quality) {
    try {
      const formData = new FormData()
      const photo = this.getUserPhotoAsFile(quality)
      formData.append('file', photo)
      const response = await axios.post(`${backURL}/liveness/detect/`, formData)
      return response.data.prediction
    } catch (e) {
      throw new Error(e)
    }
  }

  async setQuality (avgTime, avgSpeed) {
    const avgLength = Math.abs(this.uploadTimeSeconds * avgSpeed)
    let contentLength = Infinity
    while (contentLength > avgLength && this.imageQuality > 0.5) {
      console.log(`
      IMAGE QUALITY: ${this.imageQuality}
      CONTENT LENGTH: ${contentLength}
      AVERAGE LENGTH: ${avgLength}
      contentLength > avgLength: ${contentLength > avgLength}
      `)
      this.imageQuality -= 0.05
      const photo = this.getUserPhotoAsFile()
      const formData = new FormData()
      formData.append('image', photo)
      formData.append('main_session_id', `${this.mainSession}`)
      contentLength = this.estimateContentLength(formData)
    }
    console.log(`Average Speed: ${avgSpeed} kbps\nAverage Time: ${avgTime} s\nImage Quality: ${this.imageQuality}`)
  }

  async headPositionAPI (_params) {
    const params = {
      ..._params,
      border: 'bv-b-cg'
    }

    const formData = new FormData()
    formData.append('file', this.photo)
    // if (params.stepName === 'further') {
    //   formData.set('close_face', JSON.stringify(false))
    // }
    if (params.stepName === 'center') {
      formData.set('close_face', JSON.stringify(false))
    } else if (params.stepName === 'closer') {
      formData.set('close_face', JSON.stringify(true))
    }
    formData.append('main_session_id', `${this.mainSession}`)
    // Alex say it's not necessary
    // axios.post(`${backURL}/liveness/head-position/`, formData) // To save MainSession on backend
    try {
      const response = await axios.post(`${backURL}/liveness/face-distance/`, formData)
      if (this.steps[params.index].finished) return
      const message = response.data.message

      if (message === 'Too Far') {
        this.distanceErrorText.setAttribute('class', 'bv-distance-error_active')
        this.distanceErrorText.innerText = languages[this.language].distance.tooFar
      }
      if (message === 'Too Close') {
        this.distanceErrorText.setAttribute('class', 'bv-distance-error_active')
        this.distanceErrorText.innerText = languages[this.language].distance.tooClose
      }
      if (message === 'Face not Found') {
        this.distanceErrorText.setAttribute('class', 'bv-distance-error_active')
        this.distanceErrorText.innerText = languages[this.language].distance.notFound
      }

      if ((message === 'Ok' && params.stepName === 'closer') ||
          (message === 'Ok' && params.stepName === 'center')) {
        this.distanceErrorText.setAttribute('class', 'bv-distance-error_hidden')
        if (this.steps[params.index].successCounter === 0) {
          this.steps[params.index].finished = true
        }
        if (!this.steps[params.index].finished) {
          this.steps[params.index].successCounter++
        }
        this.changeUI(params)
        this.changeSvgColor('#008000')
      }
      if (!this.steps[this.steps.length - 1].finished) {
        await pause(0.5)
        this.changeSvgColor('#ffffff', '#000000')
      }
    } catch (error) {
      if (error.response.status === 409) {
        this.isFinished = true
        this.result.reason = 'different_faces'
        axios.post(`${backURL}/liveness/failure-reason/`, {
          main_session_id: this.mainSession,
          failure_reason: this.result.reason
        }, {
          headers: {
            Authorization: `API_KEY ${this.API_KEY}`
          }
        })
        console.log('Different faces on each step')
      }
    }
  }

  allStepsDone () {
    for (let i = 0; i < this.steps.length; i++) {
      if (!this.steps[i].finished) return false
    }
    return true
  }

  async telegramBotApi (mainSessionId, technology, customText, success, image, chatId) {
    const fd = new FormData()
    fd.append('main_session_id', mainSessionId)
    fd.append('technology', technology)
    fd.append('custom_text', customText)
    fd.append('success', success)
    if (typeof chatId !== 'undefined') {
      fd.append('chat_id', chatId)
    }
    if (typeof image === 'string') {
      fd.append('image', this.convertToFile(image, 'facePhoto.jpg'))
    } else if (typeof image === 'object') {
      fd.append('image', image)
    }
    try {
      return await axios.post(`${backURL}/telegram-bot/technology-notify/`, fd)
    } catch (e) {
      console.log(e)
      return e
    }
  }

  async resultAPI () {
    const successParams = {
      text: languages[this.language].success,
      border: 'bv-b-cg'
    }
    const failParams = {
      text: languages[this.language].failure,
      border: 'bv-b-cr'
    }
    try {
      const formData = new FormData()
      formData.append('main_session_id', `${this.mainSession}`)
      const response = await axios.post(`${backURL}/liveness/result/`, formData)

      if (response.data.liveness_result) {
        this.changeUI(successParams)
        this.changeSvgColor('#008000')
        this.result.status = true
        this.result.data = response.data
      } else {
        this.changeUI(failParams)
        this.changeSvgColor('#f20000')
        this.result.status = false
      }
      saveAndCheckSessions(this.mainSession)
    } catch (e) {
      this.changeUI(failParams)
      this.changeSvgColor('#f20000')
      this.result.status = false
    }
    this.isFinished = true
  }
}
