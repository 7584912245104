// noinspection DuplicatedCode

import { DocumentStructure } from '../struct/DocumentStructure'
import axios from 'axios'
import { languages } from '../utils/lang'

const backURL = process.env.APP_BACKEND_URL

async function pause (seconds) {
  return await new Promise((resolve) => setTimeout(resolve, seconds * 1000))
}

export class DocumentLogic extends DocumentStructure {
  constructor (props, fileOrCameraResult) {
    super()
    this.mainSession = props.mainSession
    this.mainConfig = props.mainConfig
    this.API_KEY = props.API_KEY
    if (fileOrCameraResult) {
      this.fileOrCameraResult = fileOrCameraResult
    }
    this.isPause = false

    this.mainSession = props.mainSession
    this.isFinished = false
    this.result = {
      status: false,
      data: {},
      main_session: this.mainSession
    }
    this.steps = [
      {
        stepName: 'docFront',
        text: languages[this.language].docFront,
        status: false
      },
      {
        stepName: 'docBack',
        text: languages[this.language].docBack,
        status: false
      }
    ]

    this.langButton.onclick = () => {
      this.changeLanguage((language) => {
        for (let i = 0; i < this.steps.length; i++) {
          const key = this.steps[i].stepName
          this.steps[i].text = languages[language][key]
        }
      })
    }

    this.camera.onclick = async () => {
      const deviceIndex = this.devicesList.indexOf(this.currentDevice)
      const device = deviceIndex === this.devicesList.length - 1 ? this.devicesList[0] : this.devicesList[deviceIndex + 1]
      if (['Android', 'iOS'].includes(this.os)) {
        if (this.devicesList.indexOf(device) === 1) this.video.className = ''
        else if (this.devicesList.indexOf(device) === 0) this.video.className = 'mirror-class'
      }
      await this.startVideoStream(device)
    }

    this.mirror.onclick = async () => {
      const className = this.video.className
      if (className === '') this.video.className = 'mirror-class'
      else this.video.className = ''
      // await this.startVideoStream(this.currentDevice)
    }

    this.docSides = this.mainConfig.document_verification_side
    this.selectedCountry = this.mainConfig.document_country
  }

  getConstraints (deviceId, width, height) {
    if (window.innerWidth <= 650) {
      return {
        video: {
          deviceId: deviceId,
          width: { ideal: 640 },
          height: { ideal: 480 }
        }
      }
    }
    return {
      video: {
        deviceId: deviceId,
        width: {
          min: 640,
          ideal: width,
          max: 1920
        },
        height: {
          min: 480,
          ideal: height,
          max: 1080
        }
      }
    }
  }

  async startVideoStream (deviceId = null, width = 1280, height = 720) {
    this.stopVideoStream()
    const constraints = this.getConstraints(deviceId, width, height)
    console.log(constraints)
    try {
      if (!this.video.srcObject) {
        console.log('no video source')
        const stream = await navigator.mediaDevices.getUserMedia(constraints)
        this.devicesList = await this.getDevicesList()
        console.log(this.devicesList)
        deviceId = this.devicesList[1]
        constraints.video.deviceId = deviceId
        this.currentDevice = deviceId
        if (['Android', 'iOS'].includes(this.os)) this.video.className = ''
        else this.video.className = 'mirror-class'
        this.stopVideoStreamByStream(stream)
      }
      const stream = await navigator.mediaDevices.getUserMedia(constraints)
      this.streamSettings = stream.getVideoTracks()[0].getSettings()
      console.log(this.streamSettings)

      if (deviceId) this.currentDevice = deviceId
      else this.currentDevice = this.streamSettings.deviceId

      this.setStructureSize(width)

      this.video.srcObject = stream

      this.video.onloadedmetadata = () => {
        this.captureFrames = true
      }
    } catch (error) {
      throw new Error('Cannot set video stream')
    }
  }

  stopVideoStream () {
    if (this.video.srcObject) {
      const tracks = this.video.srcObject.getTracks()
      tracks.forEach(track => track.stop())
    }
  }

  stopVideoStreamByStream (stream) {
    const tracks = stream.getTracks()
    tracks.forEach(track => track.stop())
  }

  async process () {
    if (this.isFinished) {
      if (!this.steps[0].status && !this.steps[0].status) {
        this.telegramBotApi(`${this.mainSession}`, 'Document', '', JSON.stringify(false), this.photo)
      }
      this.stopVideoStream()
      this.removeStructure()
      return this.result
    }
    if (!this.captureFrames) {
      await pause(1)
      return this.process()
    }
    this.photo = this.getDocumentPhotoAsFile()
    for (let index = 0; index < this.steps.length; index++) {
      const stepName = this.steps[index].stepName
      if (this.docSides === 2) {
        if (stepName === 'docFront' && !this.steps[index].status) {
          await this.searchFront(index, stepName)
          break
        } else if (stepName === 'docBack' && !this.steps[index].status) {
          await this.searchFront(index, stepName)
          break
        }
      } else if (this.docSides === 1) {
        if (stepName === 'docFront' && !this.steps[index].status) {
          await this.searchFront(index, stepName)
          if (this.steps[0].status) {
            await this.resultAPI()
            this.stopVideoStream()
          }
        }
      }
    }
    if (this.allStepsDone()) {
      await this.resultAPI()
      this.stopVideoStream()
    }
    return this.process()
  }

  getDocumentPhotoAsFile () {
    const photoBase64 = this.getDocumentPhotoBase64()
    return this.convertToFile(photoBase64, 'documentPhoto.jpeg')
  }

  getDocumentPhotoBase64 () {
    const { width, height } = this.calculateResolution()
    this.changeCanvasResolution(width, height)
    this.drawCanvas(width, height)
    return this.canvas.toDataURL('image/jpeg')
  }

  calculateResolution () {
    if (window.innerWidth <= 640) { // mobile
      return {
        width: +this.border.style.width.slice(0, -2),
        height: +this.border.style.height.slice(0, -2)
      }
    }
    // desktop
    return {
      width: +this.border.style.width.slice(0, -2) * 1.1,
      height: +this.border.style.height.slice(0, -2) * 1.1
    }
  }

  convertToFile (dataURL, filename) {
    let array = []
    let mime = ''
    try {
      array = dataURL.split(',')
      mime = array[0].match(/:(.*?)/)[1] || '.jpeg'
    } catch (e) {
      dataURL = `data:image/png;base64,${dataURL}`
      array = dataURL.split(',')
      mime = array[0].match(/:(.*?)/)[1] || '.jpeg'
    }
    const temp = atob(array[1])
    let n = temp.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = temp.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  allStepsDone () {
    for (let i = 0; i < this.steps.length; i++) {
      if (!this.steps[i].status) return false
    }
    return true
  }

  async searchFront (index, stepName) {
    const params = {
      text: this.steps[index].text,
      border: 'biometric-docVerification-border-circle-red'
    }
    this.changeUI(params)
    await pause(2)
    this.changeUI(params)

    setTimeout(() => {
      if (!this.steps[index].status) {
        this.changeUI({ ...params, border: 'biometric-docVerification-border-circle-red' })
        this.isFinished = true
      }
    }, 35 * 1000)

    if (this.isPause) {
      await pause(3)
      this.photo = this.getDocumentPhotoAsFile()
      this.isPause = false
    }

    await this.searchAPI(index, stepName)
  }

  async searchAPI (index, stepName) {
    const params = {
      text: languages[this.language].docSuccess,
      border: 'biometric-docVerification-border-circle-green'
    }
    const formData = new FormData()
    formData.append('document_image', this.photo)
    formData.append('main_session_id', `${this.mainSession}`)
    formData.append('country_code', `${this.selectedCountry}`)
    if (stepName === 'docFront') {
      formData.set('frontside', JSON.stringify(true))
    } else if (stepName === 'docBack') {
      formData.set('frontside', JSON.stringify(false))
    }

    try {
      const response = await axios.post(`${backURL}/doc-verification/search/document/`, formData)
      this.steps[index].status = true
      this.documentSession = response.data.document_session_id
      this.changeUI(params)
      await pause(1)
      this.isPause = true
    } catch (e) {
      console.error('Search error: ', e.response.data[0])
    }
  }

  async telegramBotApi (mainSessionId, technology, customText, success, image) {
    const fd = new FormData()
    fd.append('main_session_id', mainSessionId)
    fd.append('technology', technology)
    fd.append('custom-text', customText)
    fd.append('success', success)
    if (typeof image === 'string') {
      fd.append('image', this.convertToFile(image, 'facePhoto.jpg'))
    } else if (typeof image === 'object') {
      fd.append('image', image)
    }
    try {
      return await axios.post(`${backURL}/telegram-bot/technology-notify/`, fd)
    } catch (e) {
      console.log(e)
      return e
    }
  }

  async resultAPI () {
    const successParams = {
      text: languages[this.language].docSuccess,
      border: 'biometric-docVerification-border-circle-green',
      arrow: null
    }
    const failParams = {
      text: languages[this.language].docFailure,
      border: 'biometric-docVerification-border-circle-red',
      arrow: null
    }
    const formData = new FormData()
    formData.append('main_session_id', `${this.mainSession}`)
    formData.append('country_code', `${this.selectedCountry}`)
    try {
      const response = await axios.post(`${backURL}/doc-verification/parse-result/`, formData)
      this.result.data = response.data
      // this.result.data.faceResult = this.parseBase64ImageFromResponse(response.data.face_photo)
      this.changeUI(successParams)
      this.result.status = true
      this.telegramBotApi(`${this.mainSession}`, 'Document', '', JSON.stringify(true), response.data.images.face_photo)
    } catch (e) {
      this.changeUI(failParams)
      this.telegramBotApi(`${this.mainSession}`, 'Document', '', JSON.stringify(false), this.photo)
    }
    this.isFinished = true
  }

  parseBase64ImageFromResponse (image) {
    image = image.replaceAll("b'", '').replaceAll("'", '')
    return image
  }
}
