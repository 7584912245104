// noinspection DuplicatedCode
// import img from '../img/logo.png'
import { languages } from '../utils/lang'
import logoDark from '../img/logoDark.svg'

const backURL = process.env.APP_BACKEND_URL
const back = backURL.split('/v1')[0]

export class DocumentStructure {
  constructor () {
    this.root = document.getElementById('biometric-verification')
    this.os = this.getOS()
    this.language = localStorage.getItem('language') || 'ru'
    localStorage.setItem('language', this.language)
    this.verificationText = languages[this.language].docFront
    this.createStructure()

    this.video = document.getElementById('biometric-verification-video')
    this.camera = document.getElementById('biometric-verification-camera-button')
    this.mirror = document.getElementById('biometric-verification-mirror-button')
    this.wrapper = document.getElementById('biometric-verification-wrapper')
    this.text = document.getElementById('biometric-verification-text')
    this.border = document.getElementById('biometric-verification-border')
    this.canvas = document.getElementById('biometric-verification-canvas')
    this.langButton = document.getElementById('bv-lang')
    this.logo = document.getElementById('logo')
    this.logo.setAttribute('src', logoDark)
    this.streamSettings = null
  }

  /*
    * =====================================================================
    * MAIN Methods
    * =====================================================================
  */

  createStructure () {
    this.root.innerHTML = `
      <div id="biometric-verification" style="width: 1280px; height: 720px;">
        <div class="spinner"></div>
        <div id="biometric-verification-main-wrapper">
          <video id="biometric-verification-video" autoPlay playsInline></video>
          <div id="biometric-verification-wrapper" style="width: 1280px; height: 720px;">
            <div class="biometric-verification-video-controls">
              <button class="biometric-docVerification-lang-button" id="bv-lang">${this.language}</button>
              <button id="biometric-verification-mirror-button">
                <img src="https://test.biometric.kz/svg/mirror.svg" alt="mirror video" />
              </button>
              <button id="biometric-verification-camera-button">
                <img style="color: red" src="https://test.biometric.kz/svg/switch-camera.svg" alt="switch camera" />
              </button>
            </div>
            <div id="biometric-verification-border" class="biometric-docVerification-border-circle-red" style="width: 600px; height: 375px;">
              <div id="biometric-verification-text" class="">${this.verificationText}</div>
            </div>
            <img alt="logo" id="logo" class="logo">
          </div>
          <canvas id="biometric-verification-canvas" width="600" height="375" style="display: none;"></canvas>
        </div>
      </div>
    `
  }

  getOS () {
    const userAgent = window.navigator.userAgent
    const platform = window.navigator.platform
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows'
    } else if (/Android/.test(userAgent)) {
      os = 'Android'
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux'
    }

    return os
  }

  /*
   * @return - array of video media devices. If user device is phone then: [0] - back camera, [1] - front camera
   */
  async getDevicesList () {
    let result = []
    try {
      const devices = await navigator.mediaDevices.enumerateDevices()
      if (this.os === 'iOs') result = this.getIOSDevices(devices)
      else result = this.getDefaultDevices(devices)
      // if (this.os === 'Android') result = this.getAndroidDevices(devices)
      // else if (this.os === 'iOS') result = this.getIOSDevices(devices)
      // if (!result.length) result = this.getDefaultDevices(devices)
    } catch (err) {
      console.log(err.name + ': ' + err.message)
    }
    return result
  }

  /*
   * @param devices - list of available media devices
   * @return - array of video media devices. [0] - back camera, [1] - front camera
   */
  getAndroidDevices (devices) {
    const result = []
    for (const device of devices) {
      const labelArray = device.label.split(' ')
      if (device.kind === 'videoinput') {
        const cameraNumber = labelArray[1][0]
        if (cameraNumber === '0' || cameraNumber === '1') {
          if (labelArray[3] === 'front') result[1] = device.deviceId
          else if (labelArray[3] === 'back') result[0] = device.deviceId
        }
      }
    }
    console.dir(result)
    return result
  }

  /*
   * @param devices - list of available media devices
   * @return - array of video media devices. [0] - back camera, [1] - front camera
   */
  getIOSDevices (devices) {
    const result = []
    for (const device of devices) {
      if (device.kind === 'videoinput') {
        const labelArray = device.label.split(' ')
        if (labelArray.length === 2) {
          const cameraLabel = labelArray[0]
          if (cameraLabel === 'Front') result[1] = device.deviceId
          else if (cameraLabel === 'Back') result[0] = device.deviceId
        } else {
          const cameraLabel = labelArray[2]
          if (cameraLabel === 'передней') result[1] = device.deviceId
          else if (cameraLabel === 'задней') result[0] = device.deviceId
        }
        // iOS videoinput device labels
        // Камера на передней панели
        // Камера на задней панели
        // Front camera
        // Back camera
      }
    }
    return result
  }

  /*
   * @param devices - list of available media devices
   * @return - array of video media devices.
   */
  getDefaultDevices (devices) {
    const result = []
    for (const device of devices) {
      if (device.kind === 'videoinput') result.push(device.deviceId)
    }
    return result
  }

  removeStructure () {
    while (this.root.firstChild) {
      this.root.removeChild(this.root.lastChild)
    }
  }

  /*
  * =====================================================================
  * CONFIGURE Structure
  * =====================================================================
  */

  setStructureSize (width) {
    if (window.innerWidth > 650) {
      this.desktopStructureSize(width)
    } else {
      this.mobileStructureSize()
    }
  }

  desktopStructureSize (width, height) {
    if (width > this.streamSettings.width) width = this.streamSettings.width
    else if (width < 640) width = 640

    if (height > this.streamSettings.height) height = this.streamSettings.height
    else if (height < 480) height = 480

    this.root.style.width = width + 'px'
    this.root.style.height = height + 'px'
    this.wrapper.style.width = width + 'px'
    this.wrapper.style.height = height + 'px'
    this.setDesktopSquareSize(width)
  }

  setDesktopSquareSize (width) {
    let finalWidth = width * 0.85
    finalWidth = finalWidth > 600 ? 600 : finalWidth
    const finalHeight = finalWidth * 0.625
    this.border.style.width = finalWidth + 'px'
    this.border.style.height = finalHeight + 'px'
  }

  mobileStructureSize () {
    const width = window.innerWidth
    const height = window.innerHeight

    this.root.style.width = width + 'px'
    this.root.style.height = height + 'px'
    this.wrapper.style.width = width + 'px'
    this.wrapper.style.height = height + 'px'
    this.setMobileSquareSize(width, height)
  }

  setMobileSquareSize (width, height) {
    const finalWidth = width * 0.9
    const finalHeight = height * 0.33
    this.border.style.marginTop = '38px'
    this.border.style.width = finalWidth + 'px'
    this.border.style.height = finalHeight + 'px'
  }

  /*
    * =====================================================================
    * HELPER Methods
    * =====================================================================
  */

  changeCanvasResolution (width, height) {
    this.canvas.setAttribute('width', `${width}`)
    this.canvas.setAttribute('height', `${height}`)
  }

  drawCanvas (width, height) {
    const context = this.canvas.getContext('2d')
    let startYPosition = (this.video.videoHeight / 2) - (height / 1.9)
    if (window.innerWidth <= 640) startYPosition = (this.video.videoHeight / 2.1) - (height / 2.1)
    context.drawImage(
      this.video,
      (this.video.videoWidth / 2) - (width / 2),
      startYPosition,
      width,
      height,
      0,
      0,
      width,
      height
    )
  }

  changeUI (params) {
    this.text.innerText = params.text
    if (this.text.innerText.length === 0) {
      this.text.className = 'display-none'
    } else {
      this.text.className = ''
    }
    this.border.className = params.border
  }

  changeLanguage (callback) {
    if (this.language === 'ru') {
      this.language = 'en'
      this.langButton.innerText = 'en'
    } else if (this.language === 'en') {
      this.language = 'kz'
      this.langButton.innerText = 'kz'
    } else if (this.language === 'kz') {
      this.language = 'ru'
      this.langButton.innerText = 'ru'
    }
    localStorage.setItem('language', this.language)
    callback(this.language)
  }
}
