// peer connection
let pc = null
// const backURL = process.env.APP_BACKEND_URL
// const back = backURL.split('/v1')[0]
// data channel
const dc = null

function createPeerConnection (stream) {
  const config = {
    sdpSemantics: 'unified-plan'
  }

  // config.iceServers = [{ urls: ['stun:stun.l.google.com:19302'] }]

  pc = new RTCPeerConnection(config)

  // connect audio / video
  pc.addEventListener('track', function (evt) {
    stream = evt.streams[0]
  })

  return pc
}

function negotiate (session) {
  return pc.createOffer().then(function (offer) {
    return pc.setLocalDescription(offer)
  }).then(function () {
    // wait for ICE gathering to complete
    return new Promise(function (resolve) {
      if (pc.iceGatheringState === 'complete') {
        resolve()
      } else {
        function checkState () {
          if (pc.iceGatheringState === 'complete') {
            pc.removeEventListener('icegatheringstatechange', checkState)
            resolve()
          }
        }
        pc.addEventListener('icegatheringstatechange', checkState)
      }
    })
  }).then(function () {
    const offer = pc.localDescription
    const codec = 'H264/90000'
    let sdp = null
    if (codec !== 'default') {
      sdp = sdpFilterCodec('video', codec, offer.sdp)
    }
    return fetch('https://test.biometric.kz/webrtc/', {
      body: JSON.stringify({
        sdp: sdp,
        type: offer.type,
        main_session_id: session
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
  }).then(function (response) {
    return response.json()
  }).then(function (answer) {
    console.log(answer.sdp)
    return pc.setRemoteDescription(answer)
  }).catch(function (e) {
    // alert(e)
    console.log(e)
  })
}

export function startNurbek (session, stream) {
  pc = createPeerConnection(stream)

  stream.getTracks().forEach(function (track) {
    pc.addTrack(track, stream)
  })
  return negotiate(session)
}

export function stopNurbek () {
  // close transceivers
  if (pc.getTransceivers) {
    pc.getTransceivers().forEach(function (transceiver) {
      if (transceiver.stop) {
        transceiver.stop()
      }
    })
  }

  // close local audio / video
  // pc.getSenders().forEach(function (sender) {
  //   sender.track.stop()
  // })

  // close peer connection
  setTimeout(function () {
    pc.close()
  }, 500)
}

function sdpFilterCodec (kind, codec, realSdp) {
  const allowed = []
  const rtxRegex = new RegExp('a=fmtp:(\\d+) apt=(\\d+)\r$')
  const codecRegex = new RegExp('a=rtpmap:([0-9]+) ' + escapeRegExp(codec))
  const videoRegex = new RegExp('(m=' + kind + ' .*?)( ([0-9]+))*\\s*$')

  const lines = realSdp.split('\n')

  let isKind = false
  for (var i = 0; i < lines.length; i++) {
    if (lines[i].startsWith('m=' + kind + ' ')) {
      isKind = true
    } else if (lines[i].startsWith('m=')) {
      isKind = false
    }

    if (isKind) {
      let match = lines[i].match(codecRegex)
      if (match) {
        allowed.push(parseInt(match[1]))
      }

      match = lines[i].match(rtxRegex)
      if (match && allowed.includes(parseInt(match[2]))) {
        allowed.push(parseInt(match[1]))
      }
    }
  }

  const skipRegex = 'a=(fmtp|rtcp-fb|rtpmap):([0-9]+)'
  let sdp = ''

  isKind = false
  for (var i = 0; i < lines.length; i++) {
    if (lines[i].startsWith('m=' + kind + ' ')) {
      isKind = true
    } else if (lines[i].startsWith('m=')) {
      isKind = false
    }

    if (isKind) {
      const skipMatch = lines[i].match(skipRegex)
      if (skipMatch && !allowed.includes(parseInt(skipMatch[2]))) {
        continue
      } else if (lines[i].match(videoRegex)) {
        sdp += lines[i].replace(videoRegex, '$1 ' + allowed.join(' ')) + '\n'
      } else {
        sdp += lines[i] + '\n'
      }
    } else {
      sdp += lines[i] + '\n'
    }
  }

  return sdp
}

function escapeRegExp (string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
