import axios from 'axios'

const backURL = process.env.APP_BACKEND_URL

export function saveAndCheckSessions (session) {
  const sessionsExist = localStorage.getItem('ssns') || false
  if (!sessionsExist) {
    console.log('saving 1st session')
    localStorage.setItem('ssns', session.toString())
  } else {
    const sessions = (localStorage.getItem('ssns')).split(',')
    if (sessions.length < 2) {
      console.log('saving session')
      session = sessions.join(',') + `,${session}`
      localStorage.setItem('ssns', session.toString())
    } else {
      console.log('removing sessions')
      localStorage.removeItem('ssns')
      sessions.push(session)
      axios.post(`${backURL}/face2face/anti-scam/`, {
        main_sessions_ids: sessions
      }).then()
    }
  }
}
