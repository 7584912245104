export class F2FStructure {
  constructor () {
    this.root = document.getElementById('biometric-verification')
    this.createStructure()
    this.loaderWrapper = document.getElementById('bv-f2f-wrapper')
  }

  createStructure () {
    const element = document.createElement('div')
    element.setAttribute('class', 'bv-f2f-wrapper')
    element.setAttribute('id', 'bv-f2f-wrapper')
    element.appendChild(this.createLoader())
    element.appendChild(this.createLoaderText())
    this.root.appendChild(element)
  }

  createLoader () {
    const element = document.createElement('div')
    element.setAttribute('class', 'bv-f2f-loader')
    element.appendChild(document.createElement('span'))
    return element
  }

  createLoaderText (text = '') {
    const element = document.createElement('p')
    element.setAttribute('id', 'biometric-verification-f2f-text')
    element.innerText = text
    return element
  }

  removeStructure () {
    while (this.root.firstChild) {
      this.root.removeChild(this.root.lastChild)
    }
  }

  changeLoaderText (param = '') {
    const text = document.getElementById('biometric-verification-f2f-text')
    text.innerHTML = param
  }
}
