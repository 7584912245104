import { languages } from '../utils/lang'
import axios from 'axios'
const backURL = process.env.APP_BACKEND_URL

export class ResultStructure {
  constructor (LDResult, DVResult, FVResult, sessionId) {
    this.LDResult = LDResult
    this.DVResult = DVResult
    this.FVResult = FVResult
    this.sessionId = sessionId
    this.criminalResponse = {}
    this.language = localStorage.getItem('language') || 'ru'
    this.root = document.getElementById('biometric-verification')
    this.createStructure()
    this.loader = document.getElementById('bv-loader')
    this.criminalButton = document.getElementById('biometric-result-criminal-detection-button')
  }

  /*
    * =====================================================================
    * MAIN Methods
    * =====================================================================
  */

  isStatusExist (key) {
    if (this[key]) {
      if (this[key].status) return true
    }
    return false
  }

  createStructure () {
    // TODO: Create Result Structure with props if they are exist
    const ldExist = this.isStatusExist('LDResult')
    const dvExist = this.isStatusExist('DVResult')
    const fvExits = this.isStatusExist('FVResult')
    if (ldExist || dvExist || fvExits) {
      this.root.appendChild(this.successTrue())
    } else {
      this.root.appendChild(this.successFalse())
    }
  }

  successTrue () {
    const element = document.createElement('div')
    element.setAttribute('class', 'biometric-result-success')
    element.appendChild(this.successTrueWrapper())
    return element
  }

  successFalse () {
    const element = document.createElement('div')
    element.setAttribute('class', 'biometric-result-failure')
    element.appendChild(this.successFalseWrapper())
    return element
  }

  /*
    * =====================================================================
    * HELPER Methods
    * =====================================================================
  */

  successTrueWrapper () {
    const element = document.createElement('div')
    element.setAttribute('class', 'biometric-result-success-wrapper')

    element.appendChild(this.createIcon('biometric-result-success-icon'))
    element.appendChild(this.createText('biometric-result-success-text', languages[this.language].result.success))

    element.appendChild(this.showDataResult())
    if (typeof this.LDResult !== 'undefined') {
      const allSessions = 'all_sessions' in this.LDResult
      if (allSessions && Object.keys(this.LDResult.all_sessions).length > 0) {
        element.appendChild(this.createSplitText())
        element.appendChild(this.createAllSessionResultTable())
      }
    }
    // element.appendChild(this.createCheckCriminalButton())
    return element
  }

  successFalseWrapper () {
    const element = document.createElement('div')
    element.setAttribute('class', 'biometric-result-failure-wrapper')

    element.appendChild(this.createIcon('biometric-result-failure-icon'))
    element.appendChild(this.createText('biometric-result-failure-text', languages[this.language].result.failure))
    element.appendChild(this.createFailureButton())

    return element
  }

  createIcon (className) {
    const element = document.createElement('div')
    element.setAttribute('class', `${className}`)
    return element
  }

  createText (className, text) {
    const element = document.createElement('div')
    element.setAttribute('class', `${className}`)
    element.innerText = text
    return element
  }

  createFailureButton () {
    const element = document.createElement('button')
    element.setAttribute('class', 'biometric-result-failure-button')
    element.onclick = () => {
      window.location.reload()
    }
    element.innerText = languages[this.language].result.restart
    return element
  }

  createSplitText () {
    const element = document.createElement('div')
    const allSession = 'all_sessions' in this.LDResult
    if (allSession) element.innerText = 'История верификаций:'
    else element.innerText = ''

    element.setAttribute('class', 'biometric-result-success-split-text')
    return element
  }

  createCheckCriminalButton () {
    const element = document.createElement('button')
    element.setAttribute('id', 'biometric-result-criminal-detection-button')
    element.setAttribute('class', 'biometric-result-criminal-detection-button')
    element.onclick = async () => {
      this.loader.setAttribute('class', 'bv-loader visible')
      this.criminalButton.setAttribute('disabled', 'disabled')
      this.criminalButton.setAttribute('class', 'biometric-result-criminal-detection-button disabled')
      await this.checkCriminalAPI()
      this.loader.setAttribute('class', 'bv-loader')
      this.criminalButton.removeAttribute('disabled')
      this.criminalButton.setAttribute('class', 'biometric-result-criminal-detection-button')
      this.createCriminalResultTable()
    }
    element.innerText = languages[this.language].result.criminalButton
    element.appendChild(this.createLoader())
    return element
  }

  createCriminalResultTable () {
    const table = document.getElementById('data-result-table')
    const tbody = document.getElementById('data-result-table-body')
    if (this.criminalResponse !== {}) {
      for (const [key, value] of Object.entries(this.criminalResponse)) {
        if (key === 'criminal_photo' && value !== null) {
          tbody.innerHTML += `<tr>
                              <td>${key}</td>
                              <td>
                                <img class="biometric-face-photo-image" src="data:image/png;base64, ${value}" alt="verification photo"/>
                              </td>
                           </tr>`
        } else if (key === 'criminal_photo' && value === null) {
          tbody.innerHTML += `<tr>
                              <td>${key}</td>
                              <td>` + languages[this.language].result.criminalResponse + `</td>
                           </tr>`
        } else {
          tbody.innerHTML += `<tr>
                              <td>${key}</td>
                              <td>${value}</td>
                           </tr>`
        }
      }
      table.appendChild(tbody)
    }
  }

  createAllSessionResultTable () {
    const table = document.createElement('table')
    const tbody = document.createElement('tbody')
    table.setAttribute('class', 'data-result-table')
    // table.setAttribute('id', 'data-result-table')
    tbody.setAttribute('class', 'data-result-table-body')
    // tbody.setAttribute('id', 'data-result-table-body')

    const allSessions = 'all_sessions' in this.LDResult
    if (allSessions) {
      for (const [key, value] of Object.entries(this.LDResult.all_sessions)) {
        const obj = value
        for (const [key, value] of Object.entries(obj)) {
          if (key === 'face_photo') {
            tbody.innerHTML += `<tr>
                              <td> ${languages[this.language].result.fields[`${key}`]}</td>
                              <td>
                                <img class="biometric-face-photo-image" src="data:image/png;base64, ${value}" alt="verification photo"/>
                              </td>
                           </tr>`
          } else if (key === 'images') {
            tbody.innerHTML += `<tr>
                              <td> ${languages[this.language].result.fields[`${key}`]}</td>
                              <td>
                                <img class="biometric-face-photo-image" src="data:image/png;base64, ${value.face_photo}" alt="verification photo"/>
                              </td>
                           </tr>`
          } else if (key === 'failure_reason' && value === null) {
            tbody.innerHTML += `<tr>
                              <td> ${languages[this.language].result.fields[`${key}`]}</td>
                              <td> ${languages[this.language].result.fields[`${value}`]}</td>
                           </tr>`
          } else if (key === 'failure_reason' && value === 'undisclosed') {
            tbody.innerHTML += `<tr>
                              <td> ${languages[this.language].result.fields[`${key}`]}</td>
                              <td> ${languages[this.language].result`${value}`}</td>
                           </tr>`
          } else if (key === 'failure_reason' && value === 'darkness') {
            tbody.innerHTML += `<tr>
                              <td> ${languages[this.language].result.fields[`${key}`]}</td>
                              <td> ${languages[this.language].result`${value}`}</td>
                           </tr>`
          } else if (key === 'failure_reason' && value === 'brightness') {
            tbody.innerHTML += `<tr>
                              <td> ${languages[this.language].result.fields[`${key}`]}</td>
                              <td> ${languages[this.language].result`${value}`}</td>
                           </tr>`
          } else {
            tbody.innerHTML += `<tr>
                              <td> ${languages[this.language].result.fields[`${key}`]}</td>
                              <td>${value}</td>
                           </tr>`
          }
        }
        tbody.innerHTML += '<br>'
      }
      table.appendChild(tbody)
    }
    return table
  }

  createLoader () {
    const element = document.createElement('div')
    element.setAttribute('id', 'bv-loader')
    element.setAttribute('class', 'bv-loader')
    element.appendChild(document.createElement('span'))
    return element
  }

  async checkCriminalAPI () {
    const fd = new FormData()
    fd.append('main_session_id', this.sessionId)
    const response = await axios.post(`${backURL}/liveness/criminal-detection/liveness/`, fd)
    this.criminalResponse = response.data
  }

  showDataResult () {
    const table = document.createElement('table')
    const tbody = document.createElement('tbody')
    table.setAttribute('class', 'data-result-table')
    table.setAttribute('id', 'data-result-table')
    tbody.setAttribute('class', 'data-result-table-body')
    tbody.setAttribute('id', 'data-result-table-body')
    if (typeof this.FVResult !== 'undefined') {
      for (const [key, value] of Object.entries(this.FVResult.data)) {
        if (key === 'liveness_photo' || key === 'document_photo') {
          tbody.innerHTML += `<tr>
                              <td> ${languages[this.language].result.fields[`${key}`]}</td>
                              <td>
                                <img class="biometric-face-photo-image" src="data:image/png;base64, ${value}" alt="verification photo"/>
                              </td>
                           </tr>`
        } else if (key === 'prediction_percent') {
          tbody.innerHTML += `<tr>
                                <td> ${languages[this.language].result.fields[`${key}`]}</td>
                                <td>${value}%</td>
                              </tr>`
        }
      }
    }
    if (typeof this.DVResult !== 'undefined') {
      if (typeof this.FVResult !== 'undefined') {
        for (const [key, value] of Object.entries(this.DVResult.data)) {
          if (key !== 'images') {
            tbody.innerHTML +=
              `<tr>
                <td>
                ${languages[this.language].result.fields[`${key}`]}
                </td>
                <td>
                ${value}
                </td>
             </tr>`
          }
        }
      } else if (typeof this.FVResult === 'undefined') {
        for (const [key, value] of Object.entries(this.DVResult.data)) {
          if (key === 'images') {
            tbody.innerHTML += `<tr>
                                <td>${languages[this.language].result.fields[`${key}`]}</td>
                                <td>
                                    <img class="biometric-face-photo-image" src="data:image/png;base64, ${value.face_photo}" alt="verification photo"/>
                                </td>
                              </tr>`
          } else {
            tbody.innerHTML += `
             <tr>
                <td>${languages[this.language].result.fields[`${key}`]}</td>
                <td>${value}</td>
             </tr>`
          }
        }
      }
    }
    if (typeof this.LDResult !== 'undefined') {
      if (typeof this.FVResult !== 'undefined') {
        for (const [key, value] of Object.entries(this.LDResult.data)) {
          if (key === 'liveness_result') {
            tbody.innerHTML += `<tr>
                                <td>${languages[this.language].result.fields[`${key}`]}</td>
                                <td>${value}</td>
                              </tr>`
          } else if (key === 'prediction') {
            tbody.innerHTML += `<tr>
                                <td>${languages[this.language].result.fields[`${key}`]}</td>
                                <td>${+value * 100}%</td>
                              </tr>`
          } else if (key === 'created_at') {
            tbody.innerHTML += `<tr>
                                <td>${languages[this.language].result.fields[`${key}`]}</td>
                                <td>${value.replace('T', ' ')}</td>
                              </tr>`
          }
        }
      } else if (typeof this.FVResult === 'undefined') {
        for (const [key, value] of Object.entries(this.LDResult.data)) {
          if (key === 'face_photo') {
            tbody.innerHTML += `<tr>
                                <td>${languages[this.language].result.fields[`${key}`]}</td>
                                <td>
                                    <img class="biometric-face-photo-image" src="data:image/png;base64, ${value}" alt="verification photo"/>
                                </td>
                              </tr>`
          } else if (key === 'liveness_result') {
            tbody.innerHTML += `<tr>
                                <td>${languages[this.language].result.fields[`${key}`]}</td>
                                <td>${value}</td>
                              </tr>`
          } else if (key === 'prediction') {
            tbody.innerHTML += `<tr>
                                <td>${languages[this.language].result.fields[`${key}`]}</td>
                                <td>${+value * 100}%</td>
                              </tr>`
          } else if (key === 'created_at') {
            tbody.innerHTML += `<tr>
                                <td>${languages[this.language].result.fields[`${key}`]}</td>
                                <td>${value.replace('T', ' ')}</td>
                              </tr>`
          }
        }
      }
    }
    table.appendChild(tbody)
    return table
  }
}
